
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as joinBK86I_45jOLRG1CZsZhKz9Q_457lklGWHjQ3rRvDUBC2a8UMeta } from "/opt/render/project/src/apps/hub/client/pages/join.vue?macro=true";
import { default as index_45sSpwM9mtuZhJjkhEqkS1cjbRCHVWA60S7W3j1KLTuwMeta } from "/opt/render/project/src/apps/hub/client/pages/index.vue?macro=true";
import { default as loginERp9eWuz_MScY24X6b4A3plfWKO5DgxLncHqwPj8hGwMeta } from "/opt/render/project/src/apps/hub/client/pages/auth/login.vue?macro=true";
import { default as callbackKEdPpeeJ6RzCKV0EtsDSMl8QM2jAzBV9Y0WEvDTkAdQMeta } from "/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue?macro=true";
import { default as verifyeiOF89LpLNmzzRrPQDS2YMP_KFf7ez3DeS7wK0C0_pUMeta } from "/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue?macro=true";
import { default as indexF_45J_45EzLxYd6cXvMMYIFyuLSYeOffd6_6hF4SWR8kWAQMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/index.vue?macro=true";
import { default as members9_8tLmKO3wqGG3pT2SibGz3_sQYSvSDR2pY0UxZN9fgMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/members.vue?macro=true";
import { default as invitationsZuffb1ITFcq2vGJwwZUnrGeBhsq8LqPNaKNJUCHh2a4Meta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/invitations.vue?macro=true";
import { default as creditsiRFSFod70uguY4PiU5bh5MmyGVQ6l7nzxEDQ8_oYvwMMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits.vue?macro=true";
import { default as requestsTLdKz37xjcRCrlQo_se28h2jCTZV4tGkHhjEzgIpVQkMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/requests.vue?macro=true";
import { default as satisfactionymJlAjDclfp6A9rp29H7r92nYnYwBZgXpu8jQd0Kd5cMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/satisfaction.vue?macro=true";
import { default as indexP82KB4orHOJsOXyvMFIV9vBAdQ8ERtWd9Ezj_WFNF_QMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/index.vue?macro=true";
import { default as _91sessionId_93kCYceTaOZjXUx5b_qt_URWodRStfznjB_dXqhAYSS4gMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/[sessionId].vue?macro=true";
import { default as _91organizationSlug_93WlJI8bsnVnihgloXKD7MN10pjtIe6ntbyiDHbJ6pHW0Meta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug].vue?macro=true";
import { default as reset_45passwordoBtlWuj65rNWjJqNWmyBQqbEMJUMwySurAmvqJHGAiUMeta } from "/opt/render/project/src/apps/hub/client/pages/account/reset-password.vue?macro=true";
export default [
  {
    name: "join",
    path: "/join",
    meta: joinBK86I_45jOLRG1CZsZhKz9Q_457lklGWHjQ3rRvDUBC2a8UMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/join.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index_45sSpwM9mtuZhJjkhEqkS1cjbRCHVWA60S7W3j1KLTuwMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/index.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginERp9eWuz_MScY24X6b4A3plfWKO5DgxLncHqwPj8hGwMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/auth/login.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackKEdPpeeJ6RzCKV0EtsDSMl8QM2jAzBV9Y0WEvDTkAdQMeta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/callback.vue")
  },
  {
    name: "auth-v1-verify",
    path: "/auth/v1/verify",
    meta: verifyeiOF89LpLNmzzRrPQDS2YMP_KFf7ez3DeS7wK0C0_pUMeta || {},
    component: () => import("/opt/render/project/src/packages/authentication/client/pages/auth/v1/verify.vue")
  },
  {
    name: "organizationSlug",
    path: "/:organizationSlug()",
    meta: _91organizationSlug_93WlJI8bsnVnihgloXKD7MN10pjtIe6ntbyiDHbJ6pHW0Meta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug].vue"),
    children: [
  {
    name: creditsiRFSFod70uguY4PiU5bh5MmyGVQ6l7nzxEDQ8_oYvwMMeta?.name,
    path: "credits",
    meta: creditsiRFSFod70uguY4PiU5bh5MmyGVQ6l7nzxEDQ8_oYvwMMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits.vue"),
    children: [
  {
    name: "organizationSlug-credits",
    path: "",
    meta: indexF_45J_45EzLxYd6cXvMMYIFyuLSYeOffd6_6hF4SWR8kWAQMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/index.vue")
  },
  {
    name: "organizationSlug-credits-members",
    path: "members",
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/members.vue")
  },
  {
    name: "organizationSlug-credits-invitations",
    path: "invitations",
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/invitations.vue")
  }
]
  },
  {
    name: "organizationSlug-requests",
    path: "requests",
    meta: requestsTLdKz37xjcRCrlQo_se28h2jCTZV4tGkHhjEzgIpVQkMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/requests.vue")
  },
  {
    name: "organizationSlug-satisfaction",
    path: "satisfaction",
    meta: satisfactionymJlAjDclfp6A9rp29H7r92nYnYwBZgXpu8jQd0Kd5cMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/satisfaction.vue")
  },
  {
    name: "organizationSlug-sessions",
    path: "sessions",
    meta: indexP82KB4orHOJsOXyvMFIV9vBAdQ8ERtWd9Ezj_WFNF_QMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/index.vue")
  },
  {
    name: "organizationSlug-sessions-sessionId",
    path: "sessions/:sessionId()",
    meta: _91sessionId_93kCYceTaOZjXUx5b_qt_URWodRStfznjB_dXqhAYSS4gMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/[sessionId].vue")
  }
]
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    meta: reset_45passwordoBtlWuj65rNWjJqNWmyBQqbEMJUMwySurAmvqJHGAiUMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/account/reset-password.vue")
  }
]